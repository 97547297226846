<template>
  <div class="info-page">
    <van-nav-bar title="气象数据" left-text="返回" left-arrow @click-left="onClickLeft"/>
    <div class="glo-content">
        <div v-if="loading">
            <van-loading type="spinner" vertical>加载中</van-loading>
        </div>
        <div v-else>
            <van-collapse v-model="activeNames" accordion>
                <van-collapse-item v-for="(item,index) in sData" :key="index" :title="item.devName || '暂无名称'" :name="index">
                    <van-cell title="设备编号" :value="item.devCode" />
                    <van-cell title="设备类型" value="气象模块" />
                    <van-cell title="设备时间" :value="item.melvesStatus.devTime" />
                    <van-cell title="环境温度" :value="item.melvesStatus.airTemp +' ℃'" />
                    <van-cell title="环境湿度" :value="item.melvesStatus.airHumi +' %'" />
                    <van-cell title="大气压" :value="item.melvesStatus.atmoPressure +' Pa'" />
                    <van-cell title="能见度" :value="item.melvesStatus.visibility +' mm'" />
                    <van-cell title="光照强度" :value="item.melvesStatus.illuminance +' Lux'" />
                    <!-- <van-cell title="PM1.0" :value="item.melvesStatus.pm1p0 +' ug/m³'" /> -->
                    <van-cell title="PM2.5" :value="item.melvesStatus.pm2p5 +' ug/m³'" />
                    <!-- <van-cell title="PM10" :value="item.melvesStatus.pm10 +' ug/m³'" /> -->
                    <van-cell title="路面温度" :value="item.melvesStatus.roadbedTemp +' ℃'" />
                    <van-cell title="路面-10cm温度" :value="item.melvesStatus.road10cmTemp +' ℃'" />
                    <van-cell title="路面状态" :value="item.melvesStatus.roadState == 1 ? '潮湿' 
                        : item.melvesStatus.roadState == 2 ? '积水' 
                        : item.melvesStatus.roadState == 3 ? '积雪' 
                        : item.melvesStatus.roadState == 4 ? '结冰' :'干燥'" 
                    />
                    <van-cell title="路面覆盖厚度" :value="item.melvesStatus.coverThickness +' mm'" />
                    <van-cell title="电池电压" :value="((item.melvesStatus.batVolt || 0)*0.001) +' V'" />
                    <!-- <van-cell title="工作温度" :value="(item.melvesStatus.chipTemp || 0) +' ℃'" /> -->
                    <!-- <van-cell title="卫星数" :value="item.melvesStatus.satelliteTrack || 0" /> -->
                    <!-- <van-cell title="运行时间" :value="(item.runTime || 0) + ' s'" />
                    <van-cell title="硬件版本" :value="(item.hardwareVersion || '1.0') +' v'" />
                    <van-cell title="软件版本" :value="(item.softwareVersion || '1.0') +' v'" /> -->
                </van-collapse-item>
            </van-collapse>
        </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
        activeNames:0,
        bridgeCode:'',
        loading:true,
        sData:[]
    }
  },
  methods:{
      onClickLeft(){
        this.$router.go(-1);//返回上一层
      },
      // 获取设备传感器信息
      getData(){
          this.$api.NEWBRIDGE.melvesNewData({projectCode:this.projectCode}).then( d => {
            this.sData = d.filter( v => v);
            this.loading = false;
        })
      }
  },
  mounted(){
      document.querySelector('body').setAttribute('style', 'background-color:#f0f0f0');
      this.projectCode = window.sessionStorage.getItem('projectCode');
      this.projectCode ? this.getData() : void 0;
  },
  beforeDestroy() {
      document.querySelector('body').removeAttribute('style')
}
}
</script>
<style lang="scss" scoped>
.van-cell__title{text-align: left;}
.van-cell__value {
    -webkit-box-flex: inherit;
    -webkit-flex: inherit;
    flex: inherit;
    display: inherit;
}
.van-loading--vertical {
    margin: 50% auto;
}
</style>